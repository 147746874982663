<template>
  <div style="text-align: left">{{ isaddupgood ? "上架商品" : "修改上架信息" }}</div>
  <el-form
    v-if="isaddupgood"
    ref="form"
    :model="upseachgoodform"
    :inline="true"
    style="
      margin-top: 20px;
      margin-left: 16px;
      display: flex;
      justify-content: flex-start;
    "
    label-width="80px"
  >
    <el-form-item label="商品分类">
      <el-cascader
        v-model="casvalue"
        :options="classOption"
        :props="props"
        @change="handleChange"
        size="small"
        :show-all-levels="false"
      >
        <template #default="{ node, data }">
          <span>{{ data.name }}</span>
          <span v-if="node.isLeaf && data.upsale" style="color:red;">(在售)</span>
        </template>
      </el-cascader>
      <!-- <el-select
        v-model="upseachgoodform.categoryId"
        size="small"
        placeholder="请选择分类"
      >
        <el-option
          v-for="item in classOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>-->
    </el-form-item>
    <!-- <el-form-item label="商品名称">
      <el-input
        v-model="upseachgoodform.productName"
        placeholder="请输入商品名称"
        size="small"
      ></el-input>
    </el-form-item>-->
  </el-form>
  <!-- 基本信息 -->
  <el-form
    v-if="isshowbase"
    :model="upgoodbaseform"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    class="demo-ruleForm"
    style="margin-top: 20px"
  >
    <el-form-item label="商品名称" style="width: 50%; text-align: left">
      <span>{{ waitupGood.name }}</span>
    </el-form-item>
    <el-form-item label="商品分类" style="width: 50%; text-align: left">
      <span>{{ waitupGood.cateName }}</span>
    </el-form-item>
    <el-form-item label="划线价格" style="width: 50%; text-align: left">
      <!-- <div class="displayflex">
        <el-input
          v-model="upgoodbaseform.price"
          placeholder="请输入商品价格！"
          style="width: 150px"
          @change="changetonum"
        ></el-input>
        <span style="margin-left: 15px">元</span>
      </div>-->
      <!-- <div style="text-align: left">
        <span style="color: rgba(0, 0, 0, 0.45)"
          >商品的实际购买金额，最低0.01</span
        >
      </div>-->
      <span>{{ waitupGood.basePrice + "元" }}</span>
    </el-form-item>
    <el-form-item label="上架价格" class="flexcol" prop="salePrice" size="small" style="width: 50%">
      <div class="flexrow">
        <el-input
          v-model="upgoodbaseform.salePrice"
          clearable
          placeholder="请输入上架价格"
          style="width: 140px; text-align: left"
        ></el-input>
        <span style="margin-left: 5px">元</span>
      </div>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left">上架价格仅用于电商页展示</span>
    </el-form-item>
    <el-form-item class="flexcol" label="上架数量" size="small" prop="upsaleNumber" style="width: 50%">
      <div class="flexrow">
        <el-input
          v-model="upgoodbaseform.upsaleNumber"
          clearable
          placeholder="请输入上架数量"
          style="width: 140px; text-align: left"
        ></el-input>
        <span style="margin-left: 10px">件</span>
      </div>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left">上架数量为实际产品数量</span>
    </el-form-item>
    <el-form-item label="初始销量" class="flexcol" prop="saledNumber" size="small" style="width: 50%">
      <div class="flexrow">
        <el-input
          v-model="upgoodbaseform.saledNumber"
          clearable
          placeholder="请输入初始销量"
          style="width: 140px; text-align: left"
        ></el-input>
        <span style="margin-left: 5px">件</span>
      </div>

      <span style="color: rgba(0, 0, 0, 0.45); text-align: left">用户端展示的销量 = 初始销量 + 实际销量</span>
    </el-form-item>
    <el-form-item style="display: flex">
      <el-button size="mini" type="primary" @click="nextformshow" v-show="!proformshow">下一步</el-button>
    </el-form-item>
  </el-form>
  <!-- 商品信息 -->
  <el-form
    v-if="proformshow"
    :model="waitupGood"
    ref="ruleForm"
    label-width="100px"
    class="demo-ruleForm"
  >
    <el-form-item label="商品图片" class="goodpic" style="width: 50%">
      <el-upload
        :action="UploadUrl"
        :headers="{
          authorization: Token
        }"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
        :auto-upload="true"
        :before-upload="handlebeforupload"
        accept=".mp4, .jpg, .jpeg, .png"
        :on-success="filechange"
        :file-list="productpciList"
        :limit="8"
        :on-exceed="handleexceed"
      >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <video
              style="width: 100%; height: 100%"
              v-if="file.raw && file.raw.type === 'video/mp4'"
              :src="file.url"
            ></video>
            <img v-else class="el-upload-list__item-thumbnail" :src="file.url" alt />
            <span class="el-upload-list__item-actions">
              <!-- <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>-->
              <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span
        style="color: rgba(0, 0, 0, 0.45); text-align: left"
      >建议宽高比1：1, 最多上传8张、图片大小200k为宜, 第1张将作为商品首图,单张图片不超过2M</span>
    </el-form-item>

    <el-form-item label="商品简介" prop="content" style="width: 50%">
      <el-input v-model="waitupGood.content" clearable placeholder="请输入商品得简要描述！"></el-input>
    </el-form-item>
    <el-form-item label="商品描述" prop="description" style="width: 80%">
      <vue-ueditor-wrap
        v-model="waitupGood.description"
        :config="editorConfig"
        editor-id="upgoodinfo"
      ></vue-ueditor-wrap>
    </el-form-item>
    <el-form-item style="display: flex">
      <el-button size="mini" type="primary" @click="onsubmit">提交</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import _ from 'lodash'
export default {
  data() {
    return {
      upseachgoodform: {
        categoryId: '',
        productName: ''
      },
      classOption: [],
      casvalue: [],
      props: {
        value: 'id',
        label: 'name'
      },
      upgoodbaseform: {
        salePrice: '',
        upsaleNumber: 100,
        saledNumber: 0,
      },
      rules: {
        salePrice: [
          { required: true, message: '请输入上架价格！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        upsaleNumber: [
          { required: true, message: '请输入上架数量！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      isaddupgood: true,
      proTotalPage: 0,
      waitupGood: {},
      isshowbase: false,
      proformshow: false,
      productpciList: [],
      baseUrl: '',
      submitpicList: [],
    }
  },
  computed: {
    changeUPGoodsInfo() {
      return this.$store.state.changeUPGoodsInfo
    },
    editorConfig() {
      return this.$store.state.editorConfig
    },
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
    Token() {
      return this.$store.state.Token
    },
    UploadUrl() {
      return this.$store.state.UploadUrl
    },
  },
  created() {
    this.baseUrl = this.$http.defaults.baseURL
    setTimeout(() => {
      this.getClassOPtion()
    }, 0)
    if (this.$route.params.isadd === 'false') {
      this.isaddupgood = false
      this.isshowbase = true
      this.waitupGood = this.changeUPGoodsInfo
      this.waitupGood.cateName = this.waitupGood.categoryName
      this.upseachgoodform.productName = this.waitupGood.name
      this.upgoodbaseform.salePrice = this.waitupGood.salePrice
      this.upgoodbaseform.upsaleNumber = this.waitupGood.upsaleNumber - this.waitupGood.saledNumber
      this.upgoodbaseform.saledNumber = this.waitupGood.saledNumber
      this.getimgurl()
      this.proformshow = true
      console.log(this.waitupGood)
    }
  },
  methods: {
    handlebeforupload(file) {
      return new Promise(async (resolve, reject) => {
        const isLt2M = file.size / 1048576 < 2;
        if (!isLt2M && file.type.indexOf('image') !== -1) {
          this.$message.error('上传图片请小于2M')
          reject()
        } else {
          resolve()
        }
      })
    },
    handleRemove(file) {
      var i = this.productpciList.findIndex(item => item.url === file.url)
      // var arr = this.cousepicList.filter(item => item.url !== file.url)
      // this.cousepicList = arr
      console.log(i)
      console.log(this.productpciList)
      // if (i !== -1) {
      this.productpciList.splice(i, 1)
      // }
      console.log(file.url.split('/'))

      // this.waitupGood['mediaUrl'+i]=''
      var tmpstrArr = file.url.split('/')
      var j = this.submitpicList.findIndex(item => item === tmpstrArr[tmpstrArr.length - 1])
      // this.waitupGood[keys[j]] = ''
      this.submitpicList.splice(j, 1)
      console.log('提交数组', this.submitpicList)
      console.log('绑定数组', this.productpciList)
    },
    async getallproInfo(item, caid) {
      this.getprooption(item, 1, caid)
      if (this.proTotalPage > 1) {
        for (let i = 2; i < this.proTotalPage; i++) {
          this.getprooption(item, i, caid)
        }
      }
    },
    async getprooption(item, pageno, caid) {
      const res = await this.$http.get('/nktdec/productinfo/productinfos/' + pageno + '?categoryId=' + caid + '&pageSize=1000')
      item.children = []
      console.log(item)

      res.data.content.forEach(resitem => {
        item.children.push(resitem)
      })
      this.proTotalPage = res.data.totalPages
    },
    async getClassOPtion() {
      const res = await this.$http.get('/nktdec/categoryinfo/categoryinfos?type=PRODUCT')
      this.classOption = res.data
      this.getClassOPtionchildren()
    },
    getClassOPtionchildren() {
      this.classOption.forEach(item => {
        this.getallproInfo(item, item.id)
      })
    },
    handleChange(value) {
      var tmpArr = this.classOption.filter(item => item.id === value[0])
      this.isshowbase = true
      this.waitupGood = tmpArr[0].children.filter(itemitem => itemitem.id === value[1])[0]
      this.waitupGood.cateName = tmpArr[0].name
      console.log(this.waitupGood)
      this.getimgurl()
    },

    async getimgurl() {
      this.productpciList = []
      const list = []
      for (let i = 1; i < 9; i++) {
        if (this.waitupGood.id && this.waitupGood['mediaUrl' + i].trim() !== '') {
          list.push({
            url: this.imgBaseUrl + this.waitupGood['mediaUrl' + i]

          })
          this.submitpicList[i - 1] = this.waitupGood['mediaUrl' + i]
        } else {
          break
        }
      }
      this.productpciList = list
    },
    handleexceed() {
      this.$message.error('仅限上传8个媒体文件！')
    },
    async filechange(res, file, fileList) {
      // this.productpciList = fileList
      // const formData = new FormData()
      // formData.append('file', file.raw)
      // formData.append('params', '参数')
      // const res = await this.$http(
      //   {
      //     method: 'post',
      //     url: '/nktdec/uploader/uploadfile',
      //     data: formData,
      //     headers: {
      //       'Content-Type': 'multipart/form-data;',
      //     }
      //   }
      // )
      console.log(res)
      this.productpciList.push({
        url: this.imgBaseUrl + res.result
      })
      this.submitpicList.push(res.result)
    },
    nextformshow() {
      const val = Object.values(this.upgoodbaseform)
      console.log(val)

      if (val.some(item => (item + '').trim() === '')) {
        this.$message.error('请将信息填写完整！')
        return
      }
      this.proformshow = true
    },
    async onsubmit() {
      console.log(this.upgoodbaseform)
      console.log(this.waitupGood)
      console.log(this.submitpicList)
      if (this.submitpicList[0].trim() === '') {
        this.$message.error('请上传图片！')
        return
      }
      const data = {}
      const tmpArr = _.cloneDeep(this.waitupGood)
      const basetmpArr = _.cloneDeep(this.upgoodbaseform)
      if (this.isaddupgood) {
        data.productId = tmpArr.id
        data.name = tmpArr.name
        data.content = tmpArr.content
        data.description = tmpArr.description
        data.mediaUrl1 = this.submitpicList[0]
        data.mediaUrl2 = this.submitpicList[1] || ''
        data.mediaUrl3 = this.submitpicList[2] || ''
        data.mediaUrl4 = this.submitpicList[3] || ''
        data.mediaUrl5 = this.submitpicList[4] || ''
        data.mediaUrl6 = this.submitpicList[5] || ''
        data.mediaUrl7 = this.submitpicList[6] || ''
        data.mediaUrl8 = this.submitpicList[7] || ''
        data.basePrice = tmpArr.basePrice
        data.salePrice = basetmpArr.salePrice - 0
        data.upsaleNumber = basetmpArr.upsaleNumber - 0 + (basetmpArr.saledNumber - 0)
        data.saledNumber = basetmpArr.saledNumber - 0
      } else {
        data.id = tmpArr.id
        data.productId = tmpArr.productId
        data.name = tmpArr.name
        data.content = tmpArr.content
        data.description = tmpArr.description
        data.mediaUrl1 = this.submitpicList[0]
        data.mediaUrl2 = this.submitpicList[1] || ''
        data.mediaUrl3 = this.submitpicList[2] || ''
        data.mediaUrl4 = this.submitpicList[3] || ''
        data.mediaUrl5 = this.submitpicList[4] || ''
        data.mediaUrl6 = this.submitpicList[5] || ''
        data.mediaUrl7 = this.submitpicList[6] || ''
        data.mediaUrl8 = this.submitpicList[7] || ''
        data.basePrice = tmpArr.basePrice
        data.salePrice = basetmpArr.salePrice - 0
        data.upsaleNumber = basetmpArr.upsaleNumber - 0 + (basetmpArr.saledNumber - 0)
        data.saledNumber = basetmpArr.saledNumber - 0
      }
      console.log(data)
      const res = await this.$http.post('/nktdec/bargain/bargains', data)
      console.log(res)
      if (res.data.code === '1' && this.isaddupgood) {
        this.$message.success('上架成功！')
        this.$router.push('/upgoodslist')
      } else {
        this.$message.success('修改成功！')
        this.$router.push('/upgoodslist')
      }
    }
  },
}
</script>
<style scoped>
</style>
