<template>
  <div style="text-align: left">上架商品列表</div>
  <el-form
    :inline="true"
    :model="seachForm"
    style="margin-top: 20px; display: flex; justify-content: flex-start"
    class="lineform"
  >
    <el-form-item label="商品名称">
      <el-input
        v-model="seachForm.productName"
        clearable
        size="small"
        placeholder="请输入商品名称"
      ></el-input>
    </el-form-item>
    <el-form-item label="商品分类" style="margin-left: 20px">
      <el-select
        v-model="seachForm.categoryId"
        clearable
        size="small"
        placeholder="请选择分类"
      >
        <el-option
          v-for="item in classOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
        size="mini"
        style="margin-left: 20px"
        >查询</el-button
      >
      <el-button
        type="primary"
        @click="initform"
        size="mini"
        style="margin-left: 20px"
        >清空</el-button
      >
    </el-form-item>
  </el-form>
  <div style="display: flex; justify-content: flex-start">
    <el-button type="primary" size="mini" @click="adduppro">新增上架</el-button>
  </div>
  <el-empty v-if="tableData.length === 0" description="暂无数据"></el-empty>

  <el-table
    v-else-if="tableData.length >= 0"
    :data="tableData"
    stripe
    style="width: 100%; margin-top: 30px"
  >
    <el-table-column label="商品图片" width="180">
      <template #default="scope">
        <img
          :src="scope.row.imgUrl"
          alt=""
          style="width: 80px; height: 120px; object-fit: contain"
        />
      </template>
    </el-table-column>
    <el-table-column prop="name" label="商品名称" width="180">
    </el-table-column>
    <el-table-column prop="categoryName" label="商品分类" width="180">
    </el-table-column>
    <!-- <el-table-column prop="goodDesc" label="商品描述"> </el-table-column> -->
    <!-- <el-table-column prop="goodInstan" label="所属产地"> </el-table-column> -->
    <el-table-column prop="basePrice" label="商品价格(元)"> </el-table-column>
    <el-table-column prop="salePrice" label="上架价格(元)"> </el-table-column>

    <el-table-column prop="upsaleNumber" label="上架数量(件)">
    </el-table-column>
    <el-table-column prop="saleStatus" label="商品状态">
      <template #default="scope">
        <el-tag v-if="scope.row.salesState === 'CREATED'" type="success">{{
          scope.row.saleStatus || "在售"
        }}</el-tag>
        <el-tag v-if="scope.row.salesState === 'CANCELLED'" type="info">{{
          scope.row.saleStatus
        }}</el-tag>
        <el-tag v-if="scope.row.topped" style="margin-left:10px" type="danger">{{
          "爆"
        }}</el-tag>
         <el-tag v-if="scope.row.indexed" style="margin-left:10px" type="primary">{{
          "优"
        }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="updateDate" label="时间"> </el-table-column>
    <el-table-column prop="address" label="操作">
      <template #default="scope">
        <div
          style="display: flex; justify-content: flex-start; flex-wrap: wrap;flex-direction:column"
        >
          <!-- <el-button
            type="text"
            @click="editgood(scope.row)"
            style="margin-right: 10px"
            >复制</el-button
          > -->
        <div>
          <el-button
            type="text"
            v-if="scope.row.salesState !== 'CANCELLED'"
            @click="editgood(scope.row)"
            style="margin-right: 10px"
            >编辑</el-button
          >
          <el-button
            type="text"
            style="margin-left: 0px !important"
            v-if="scope.row.salesState !== 'CANCELLED' && !scope.row.topped"
            @click="hottop(scope.row)"
            >爆款</el-button
          >
           <el-button
            type="text"
            style="margin-left: 0px !important"
            v-else-if="scope.row.salesState !== 'CANCELLED' && scope.row.topped"
            @click="hottop(scope.row)"
            >取消爆款</el-button
          >
        </div>
                 <div>
  <el-button
            type="text"
            style="margin-left: 0px !important"
            v-if="scope.row.salesState !== 'CANCELLED' && !scope.row.indexed"
            @click="hotindexed(scope.row)"
            >优选</el-button
          >
           <el-button
            type="text"
            style="margin-left: 0px !important"
            v-else-if="scope.row.salesState !== 'CANCELLED' && scope.row.indexed"
            @click="hotindexed(scope.row)"
            >取消优选</el-button
          >
          <el-button
            type="text"
            style="margin-left: 0px !important"
            v-if="scope.row.salesState !== 'CANCELLED'"
            @click="downupgoods(scope.row)"
            >下架</el-button
          >
                 </div>
                   <div>
          <el-button
            type="text"
            style="margin-left: 10px !important"
            @click="deleteupgood(scope.row)"
            >删除</el-button
          >
        </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="currentPage"
    :page-sizes="[10, 15, 20]"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    style="margin-top: 20px"
    :total="totalElement"
  >
  </el-pagination>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      baseUrl: '',
      seachForm: {
        categoryId: '',
        productName: ''
      },
      classOption: [],
      currentPage: 1,
      totalElement: 0,
      pageSize: 0,
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '上架管理',
        index: '/upgoods'
      },
      {
        name: '上架列表',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.baseUrl = this.$http.defaults.baseURL
    this.getClassOPtion().then(() => {
      this.getupgoods(this.upSeachForm)
    })
  },
  mounted(){
          console.log('1111111111', this.seachForm)
  },
  methods: {
    async handleSizeChange(val) {
      // console.log(val)
      const res = await this.$http.get('/nktdec/bargain/bargains/1' + '?pageSize=' + val + (this.seachForm.productName.trim() === '' ? '' : '&productName=' + this.seachForm.productName) + (this.seachForm.categoryId.trim() === '' ? '' : '&categoryId=' + this.seachForm.categoryId))
      this.tableData = res.data.content
      // console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.getimgUrl()
      this.getClassinfo()
      this.$store.commit('setupSeachForm',{...this.upSeachForm , currentpage : '1' , pageSize: val})
    },
    async handleCurrentChange(val) {
      // console.log(val)
      this.currentPage = val
      var str = val + '?pageSize=' + this.pageSize + (this.seachForm.productName.trim() === '' ? '' : '&productName=' + this.seachForm.productName) + (this.seachForm.categoryId.trim() === '' ? '' : '&categoryId=' + this.seachForm.categoryId)
      const res = await this.$http.get('/nktdec/bargain/bargains/' + str)
      this.tableData = res.data.content
      console.log(res)
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.getimgUrl()
      this.getClassinfo()
      this.$store.commit('setupSeachForm',{...this.upSeachForm , currentpage : val , pageSize: this.pageSize+''})
      console.log(this.upSeachForm)
    },
    query(str,obj){
      if(obj.currentpage){
           str+= obj.currentpage+'?'
           for(let i in obj){
            if(i==='currentpage') {
                continue
            }else{
              if(obj[i]!==''){
              str+='&'+i+'='+ obj[i]
            }
            }
         }
      }else{
        str+='1'
      }
      return str 
    },
    async getupgoods(obj) {
      var str = this.query('',obj)
      console.log(str)
            if(obj.categoryId){
        this.seachForm.categoryId = obj.categoryId
      }
      if(obj.productName){
        this.seachForm.productName = obj.productName
      }
      const res = await this.$http.get('/nktdec/bargain/bargains/' + str)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = obj.currentpage - 0
      this.tableData.forEach(async (item) => {
        var obj = this.classOption.find(classitem => classitem.id === item.categoryId)
        item.categoryName = obj.name
        // const res = await this.$http.get('/nktdec/uploader/images/' + item.mediaUrl1)
        // console.log(this.baseUrl + res.config.url.substring(1))
        item.imgUrl = this.imgBaseUrl + item.mediaUrl1
      })
      this.$store.commit('setupSeachForm',{...this.upSeachForm , currentpage : obj.currentpage , pageSize: '20'})
      // this.getClassinfo()
      console.log(this.upSeachForm)
    },
    getClassinfo() {
      this.tableData.forEach(item => {
        var obj = this.classOption.find(classitem => classitem.id === item.categoryId)
        // console.log(item)
        item.categoryName = obj.name
      })
    },
    getimgUrl() {
      this.tableData.forEach(async (item) => {
        // const res = await this.$http.get('/nktdec/uploader/images/' + item.mediaUrl1)
        // console.log(this.baseUrl + res.config.url.substring(1))
        item.imgUrl = this.imgBaseUrl + item.mediaUrl1
      })
    },
    editgood(row) {
      // if (row.salesState === 'CANCELLED') {
      //   this.$message.warning('已售罄！不能修改')
      // } else {
      window.sessionStorage.setItem('UpGoodsInfo', JSON.stringify(row))
      this.$store.commit('setUPGoodsInfo', row)
      this.$router.push('/editgoodsinfo/false')
      // }
    },
    adduppro() {
      this.$router.push('/editgoodsinfo/true')
    },
   async hottop(row) {
      console.log(row)
      row.topped = !row.topped
      const res = await this.$http.post('/nktdec/bargain/bargains', {
        ...row
      })
      if (res.data.code === '1') {
        this.$message.success('修改成功！')
      }
      console.log(res)
    },
     async hotindexed(row) {
      console.log(row)
      row.indexed = !row.indexed
      const res = await this.$http.post('/nktdec/bargain/bargains', {
        ...row
      })
      if (res.data.code === '1') {
        this.$message.success('修改成功！')
      }
      console.log(res)
    },
    async onSubmit() {
      console.log(this.seachForm)
      var str = '?' + (this.seachForm.productName.trim() === '' ? '' : 'productName=' + this.seachForm.productName) + (this.seachForm.categoryId.trim() === '' ? '' : (this.seachForm.productName.trim() === '' ? '' : '&') + 'categoryId=' + this.seachForm.categoryId)
      console.log(str)
      const res = await this.$http.get('/nktdec/bargain/bargains/1' + str)
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.tableData.forEach(async (item) => {
        var obj = this.classOption.find(classitem => classitem.id === item.categoryId)
        item.categoryName = obj.name
        // const res = await this.$http.get('/nktdec/uploader/images/' + item.mediaUrl1)
        // console.log(this.baseUrl + res.config.url.substring(1))
        item.imgUrl = this.imgBaseUrl + item.mediaUrl1
      })
      this.$store.commit('setupSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})
      this.getimgUrl()
      console.log(this.upSeachForm)
    },
    async initform() {
      this.seachForm.productName = ''
      this.seachForm.categoryId = ''
      const res = await this.$http.get('/nktdec/bargain/bargains/1')
      console.log(res.data)
      this.tableData = res.data.content
      this.totalElement = res.data.totalElements
      this.pageSize = res.data.size
      this.currentPage = 1
      this.tableData.forEach(async (item) => {
        var obj = this.classOption.find(classitem => classitem.id === item.categoryId)
        item.categoryName = obj.name
        const res = await this.$http.get('/nktdec/uploader/images/' + item.mediaUrl1)
        // console.log(this.baseUrl + res.config.url.substring(1))
        item.imgUrl = this.baseUrl + res.config.url.substring(1)
      })
      this.$store.commit('setupSeachForm',{...this.seachForm , currentpage : '1' , pageSize: '20'})
      this.getimgUrl()
      console.log(this.upSeachForm)
    },
    async getClassOPtion() {
      const res = await this.$http.get('/nktdec/categoryinfo/categoryinfos?type=PRODUCT')
      this.classOption = res.data
    },
    async downupgoods(row) {
      console.log(row)
      this.$confirm('此操作将下架此商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.put('/nktdec/bargain/bargain/' + row.id)
        // if (res.)
        console.log(res)
        if (res.data.code === '1') {
          this.getupgoods(this.upSeachForm)
          this.$message({
            type: 'success',
            message: '下架成功!'
          })
        }
      })
    },
    async deleteupgood(row) {
      console.log(row)
      this.$confirm('此操作将删除此商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/bargain/bargain/' + row.id)
        // if (res.)
        console.log(res)
        if (res.data.code === '1') {
          this.getupgoods(this.upSeachForm)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
      })
    },
  },
  computed: {
    imgBaseUrl() {
      return this.$store.state.imgBaseUrl
    },
    upSeachForm() {
      return this.$store.state.upSeachForm
    },
  }
}
</script>
<style scoped>
</style>
